<template>
  <div class="main">
    <base-ui>
      <v-col cols="12">
        <span v-if="config['drillType']">
          <v-autocomplete
            class="ml-3 mr-3 mt-2"
            dense
            rounded
            style="font-size: small"
            :items="allLayouts"
            outlined
            label="Select Layout"
            :value="config['layoutId']"
            @change="
              config['layoutId'] = $event
              setDrillConfig($event)
            "
          ></v-autocomplete>
          <!-- v-model="config['layoutId']" -->
        </span>
        <v-checkbox
          @change="
            config['extractFromSource'] = $event
            setDrillConfig($event)
          "
          v-if="config['layoutId'] && config['drillType'] === 'row'"
          :value="config['extractFromSource']"
          color="#1B3EC4"
          label="Carry extra Outbound params from this element"
        />
        <v-expansion-panels
          style="padding: 10px"
          v-if="config['layoutId'] && config['extractFromSource']"
        >
          <v-expansion-panel style="" expand>
            <div style="">
              <v-expansion-panel-header
                expand-icon="mdi-menu-down"
                style="padding-left: 10px; font-size: 14px"
              >
                <span>Select Params</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- <b></b> -->
                <v-container>
                  <v-row class="overrideVrowMargin">
                    <v-checkbox
                      @change="setDrillConfig($event)"
                      class="px-1"
                      v-for="(header, idx) in selectedElementHeaders"
                      :key="idx"
                      :label="header.text"
                      :value="header.value"
                      color="#1B3EC4"
                      v-model="config.outBoundParams"
                      hide-details
                    ></v-checkbox>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- <div v-if="config['extractFromSource']">
          <b>Select Params</b>
          <v-container>
            <v-row class="overrideVrowMargin">
              <v-checkbox
                class="px-1"
                v-for="(header, idx) in selectedElementHeaders"
                :key="idx"
                :label="header.text"
                :value="header.value"
                color="#1B3EC4"
                v-model="config.outBoundParams"
                hide-details
              ></v-checkbox>
            </v-row>
          </v-container>
        </div> -->
      </v-col>
      <v-divider></v-divider>
      <template
        v-if="
          config['layoutId'] &&
          config['drillType'] === 'row' &&
          inBoundParams.length
        "
      >
        <!-- <span style="font-size: 14px"
          >Carry Layout Filters value as Outbound params</span
        > -->
        <v-expansion-panels style="padding: 10px">
          <!-- border: 1px solid #c7bdbd -->
          <v-expansion-panel style="" expand>
            <div style="">
              <v-expansion-panel-header
                expand-icon="mdi-menu-down"
                style="padding-left: 10px; font-size: 14px"
              >
                <span>Carry Layout Filters value as Outbound params</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-radio-group
                  required
                  column
                  v-model="config['layoutFilter']"
                  @change="
                    config['layoutFilter'] = $event
                    setDrillConfig($event)
                  "
                >
                  <v-row>
                    <v-col cols="3">
                      <v-radio
                        color="#1B3EC4"
                        label="None"
                        value="none"
                      ></v-radio>
                    </v-col>
                    <v-col cols="3">
                      <v-radio
                        color="#1B3EC4"
                        label="All"
                        value="all"
                      ></v-radio>
                    </v-col>
                    <v-col cols="3" v-if="config['layoutFilter'] !== 'all'">
                      <v-radio
                        color="#1B3EC4"
                        label="Custom"
                        value="custom"
                      ></v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
                <template v-if="config['layoutFilter'] === 'custom'">
                  <div>
                    <span>Select Params</span>
                    <v-container>
                      <v-row class="overrideVrowMargin">
                        <template v-for="(param, idx) in layoutInboudParams">
                          <v-checkbox
                            @change="setDrillConfig($event)"
                            class="px-1"
                            :key="idx"
                            :label="param"
                            :value="param"
                            v-model="config.inBoundParams"
                            color="#1B3EC4"
                            hide-details
                          ></v-checkbox>
                        </template>
                      </v-row>
                    </v-container>
                  </div>
                </template>
              </v-expansion-panel-content>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </base-ui>
  </div>
</template>


<script>
import { cloneDeep, isEqual } from 'lodash'
import { createNamespacedHelpers as cnh, mapState as mapRootState } from 'vuex'
const { mapGetters, mapMutations } = cnh('ElmentBuilderStore')
const { mapState: mapReportBuilderState,mapMutations: mapReportBuilderMutations, mapActions: mapReportBuilderActions } =
  cnh('ReportBuilder')
export default {
  data() {
    return {
      allLayouts: [],
      config: this.initializeConfig(),
      layoutInboudParams: []
    }
  },
  computed: {
    ...mapRootState(['reportSupplements']),
    ...mapGetters(['selectedElement']),
    ...mapReportBuilderState([
      'allAvailableLayoutsData',
      'selectedContainerElementIndex',
      'layoutName',
      'selectedContainerIValue',
      'currentLayoutData',
      'inBoundParams',
      'layout'
    ]),
    selectedElementHeaders() {
      if (
        this.selectedElement.dataSource &&
        this.selectedElement.dataSource.headers &&
        this.selectedElement.dataSource.headers.length
      ) {
        return this.selectedElement.dataSource.headers.map((el) => ({
          text: el.header,
          value: el.header
        }))
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapMutations(['setSelectedElementsProps']),
    ...mapReportBuilderMutations(['setIsAllElemsAndCurrentLayoutSaved']),
    ...mapReportBuilderActions(['setLayoutDrillChildren', 'attachDrillInfo']),
    initializeConfig() {
      return {
        layoutFilter: false,
        layoutId: '',
        drillType: 'row',
        extractFromSource: false,
        outBoundParams: [],
        inBoundParams: []
      }
    },
    populateLayoutsArray() {
      this.allLayouts = this.allAvailableLayoutsData.map((l) => {
        return { text: l.name, value: l.identity }
      })
      console.log(this.allAvailableLayoutsData.length)
      if (
        !this.allAvailableLayoutsData.length &&
        (this.currentLayoutData.name || this.layoutName)
      ) {
        this.allLayouts = [
          {
            text: this.currentLayoutData.name || this.layoutName,
            value: this.currentLayoutData.identity
          }
        ]
      }
    },
    setLayoutInboudParams() {
      this.layoutInboudParams = []
      this.inBoundParams.forEach((el) => {
        this.layoutInboudParams.push(...el.filterKeys)
      })
      // this.reportSupplements.forEach((el) => {
      //   this.layoutInboudParams.push(...el.filterKeys)
      // })
    },
    mapDrillConfig() {
      this.config = this.initializeConfig()
      console.log({
        elem: this.selectedContainerElementIndex,
        con: this.selectedContainerIValue
      })
      let selectedContainerElement =
        this.layout[this.selectedContainerIValue] &&
        this.layout[this.selectedContainerIValue]['elements'][
          this.selectedContainerElementIndex
        ]
      if (
        selectedContainerElement &&
        selectedContainerElement.drill &&
        Object.keys(selectedContainerElement.drill).length
      ) {
        Object.assign(this.config, cloneDeep(selectedContainerElement.drill))
      }
      
      this.setLayoutInboudParams()
    },
    setDrillConfig(val) {
      console.log('=======>', val)
      if (!(this.$parent && this.$parent.isElementSelected)) return
      this.setSelectedElementsProps({ prop: 'drill', value: this.config })
      this.$store.commit('ReportBuilder/updateDrillInSelectedContaineElem', {
        drill: this.config
      })
      this.setIsAllElemsAndCurrentLayoutSaved(false)
      // this.attachDrillInfo(this.config)
      // console.log(`[this.config]: `, this.config);
      // this.setLayoutDrillChildren(this.config)

      // this.$store.dispatch('ReportBuilder/updateDrillInSelectedContaineElem', {
      //   drill: this.config
      // })
    }
    // onSelectLayout(layoutId) {}
  },
  watch: {
    allAvailableLayoutsData: {
      handler: 'populateLayoutsArray',
      deep: true
    },
    config: {
      handler() {
        console.log(this.config)
      },
      deep: true
    },
    selectedContainerElementIndex() {
      this.mapDrillConfig()
    },
    selectedContainerIValue() {
      this.mapDrillConfig()
    },
    inBoundParams() {
        this.setLayoutInboudParams()
    }
  },
  created() {
    console.log('<====DRILL====>', this.selectedElement.drill)
    this.mapDrillConfig()
    this.populateLayoutsArray()
  }
}
</script>


<style scoped>
</style>
<style>
.v-text-field {
  font-size: small;
}
</style>
